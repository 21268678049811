import {useState, useEffect} from 'react';
import axios from 'axios';
import './App.css';

function App() {
  let currentSnippetsArray: string[] = [];

  const [fadeOut, setFadeOut] = useState(false);
  const [snippet, setSnippet] = useState("");

  useEffect(() => {
    axios.get(`api/textSnippets`)
    .then(res => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentSnippetsArray = res.data;
      updateSnippet();
    });

      startFadingIn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getNewSnippetsArray(){
    axios.get(`api/textSnippets`)
    .then(res => {
      currentSnippetsArray = res.data;
    });

  }

  function updateSnippet() {
    let snippetsArray = currentSnippetsArray

    let nextIndex = Math.floor(Math.random() * snippetsArray.length);
    let nextSnippet = snippetsArray[nextIndex];
    snippetsArray.splice(nextIndex,1);

    if (snippetsArray.length === 0){
      console.log('im refreshing abuse array');
      getNewSnippetsArray();
    }

    setSnippet(nextSnippet);
  }

  function startFadingIn() {  
    console.log('fade out timer started');
    updateSnippet();
    
    setTimeout(() => {
      setFadeOut(true);
      console.log('fade out started');
      startFadingOut();
    }, 2000);
  }

  function startFadingOut() {  
    console.log('fade in timer started');
    setTimeout(() => {
      setFadeOut(false);
      console.log('fade in started');
      startFadingIn();
    }, 2000);
  }

  return <> 
    <div className="App"> <p className={fadeOut ? "fadeOut" : "fadeIn"}> {snippet}</p></div>
  </>;
}

export default App;
